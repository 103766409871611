import React from 'react';

import styles from './progressbar.module.css';

const Progressbar = ({ steps, activeStep }) => {
  //console.log(steps);
  return (
    <div className={styles.progressbar}>
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className={[styles.label, activeStep === index ? styles.active : ''].join(' ')}>
            {step.label}
          </div>
          {index < steps.length - 1 ? (
            <div className={styles.spacer}></div>
          ) : (
            ''
          )}
        </ React.Fragment>
      ))}
    </div>
  );
}

export default Progressbar;
