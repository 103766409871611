import React from 'react';

import FormikControl  from '../FormikControl';
import RequiredHint   from '../fields/RequiredHint';

const StepPersonal = () => {
  return (
    <>
      <FormikControl control="input" name="firstname" label="Vorname *" type="text" />
      <FormikControl control="input" name="lastname" label="Nachname *" type="text" />
      <FormikControl control="input" name="email" label="E-Mail *" type="email" />
      <RequiredHint />
    </>
  );
}

export default StepPersonal;
