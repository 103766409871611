import React from 'react';

import styles from './fieldControl.module.css';

const FieldControl = ({children, className}) => {
    return (
        <div className={[styles.fieldControl, className].join(' ')}>
            {children}
        </div>
    )
}

export default FieldControl;
