import React from 'react';

import styles from './requiredHint.module.css'

const RequiredHint = () => {
    return (
      <div className={styles.hint}>
        * Pflichtfeld
      </div>
    )
}

export default RequiredHint;
