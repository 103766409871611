import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import styles from './agb.module.css';

const Agb = () => {
  return (
    <StaticQuery
      query={graphql`
        query contentAgbQuery {
          file(name: {eq: "agb"}, extension: {eq: "md"}) {
            childMdx {
              body
            }
          }
        }
      `}
      render={data => (
        <div className={styles.content}>
          <MDXRenderer>{ data.file.childMdx.body }</MDXRenderer>
          {/* <div dangerouslySetInnerHTML={{ __html: data.file.childMdx.body }} /> */}
        </div>
      )}
    />
  )
}

export default Agb;
