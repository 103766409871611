import React from 'react';

import styles from './label.module.css';

const Label = ({children, htmlFor, className}) => {
    return (
        <label htmlFor={htmlFor} className={[styles.label, className].join(' ')}>
          {children}
        </label>
    )
}

export default Label;
