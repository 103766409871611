import React from 'react';
import { Field } from 'formik';

import FieldControl from './FieldControl';
import Label        from './Label';

import styles from './textarea.module.css';

const Textarea = props => {
    const { label, name, ...rest } = props;
    return (
      <FieldControl>
            <Label htmlFor={name} className={styles.label}>{label}</Label>
            <Field as="textarea" className={styles.textarea} id={name} name={name} {...rest} />
        </FieldControl>
    )
}

export default Textarea
