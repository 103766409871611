import React from 'react';

import Headline from '../../atoms/headline/headline';
import Text     from '../../atoms/text/text';
import Button   from '../../atoms/button/button';

import styles   from './signupSuccess.module.css';

const SignupSuccess = () => {
  return (
    <>
      <Headline>Danke für Ihre Anmeldung!</Headline>
      <Text className={styles.text}>
        Wir kümmern uns umgehend um Ihre Anmeldung und kontaktieren
        Sie schnellstmöglich.
      </Text>
      <Text className={styles.text}>
        Bei Fragen oder Anregungen wenden Sie sich bitte an:<br />
      </Text>
      <Text className={[styles.text, styles.contactList].join(' ')}>
        <span class={styles.label}>Ansprechpartner:</span> Doris Hauptenbuchner<br />
        <span class={styles.label}>M</span>  <a href="mailto:d.hauptenbuchner@heinrich-haeusler.de">d.hauptenbuchner@heinrich-haeusler.de</a><br />
        <span class={styles.label}>T</span>  <a href="tel:+49-089-551-52-231">+49 089 551 52 - 231</a><br />
      </Text>
      <Button tagName="a" position="right" href="https://www.heinrich-haeusler.de/" className={styles.button}>Zum Webshop</Button>
    </>
  );
}

export default SignupSuccess;
