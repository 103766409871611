import React from 'react';
import { Field } from 'formik';

import Label        from './Label';
import FieldControl from './FieldControl';

import styles from './input.module.css';

const Input = (props) => {
    const {label, name, ...rest} = props;
    return (
      <Field name={name}>
        {
          ({meta}) => (
            <FieldControl className={meta.error && meta.touched ? styles.error : null}>
              <Label htmlFor={name} className={styles.label}>{label} {meta.touched && meta.error}</Label>
              <Field className={styles.input} id={name} name={name} {...rest} />
            </FieldControl>
          )
        }
      </Field>
    )
}

export default Input
