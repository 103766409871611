import React from "react"

import FormikControl from "../FormikControl"
import FieldsWrap from "../fields/FieldsWrap"
import RequiredHint from "../fields/RequiredHint"

const StepCompany = () => {
  return (
    <>
      <FormikControl
        control="input"
        name="companyname"
        label="Firmenname *"
        type="text"
      />

      <FieldsWrap>
        <FormikControl
          control="input"
          name="street"
          label="Straße *"
          type="text"
        />
        <FormikControl
          control="input"
          name="number"
          label="Hausnummer *"
          type="text"
        />
      </FieldsWrap>

      <FieldsWrap>
        <FormikControl control="input" name="zip" label="PLZ *" type="text" />
        <FormikControl control="input" name="city" label="Ort *" type="text" />
      </FieldsWrap>

      <FormikControl
        control="input"
        name="phone"
        label="Telefonnummer *"
        type="text"
      />
      <FormikControl
        control="input"
        name="ustid"
        label="USt - IdNr. *"
        type="text"
      />
      <FormikControl
        control="input"
        name="taxnumber"
        label="Steuernummer *"
        type="text"
      />

      <RequiredHint />
    </>
  )
}

export default StepCompany
