import React from 'react';
import { Field } from 'formik';

import Label        from './Label';
import FieldControl from './FieldControl';

import styles       from './checkbox.module.css'

const Checkbox = props => {
    const { name, label, value, options, ...rest } = props;
    return (
      <Field name={name}>
        {
          ({ field, meta }) => {
            return (
              <FieldControl className={meta.error && meta.touched ? styles.error : null}>
                <Label className={styles.label} htmlFor={value}>
                    <Field
                      className={styles.input}
                      type="checkbox"
                      id={value}
                      {...field}
                      value={value} // value must be placed after {...field}
                      checked={field.value.includes(value)}
                      {...rest}
                    />
                    <span>{label}</span>
                </Label>
              </FieldControl>
            )
          }
        }
      </Field>
    )
}

export default Checkbox;
