import React from 'react';

import styles from './fieldsWrap.module.css';

const FieldsWrap = ({children, className}) => {
    return (
        <div className={[styles.fieldsWrap, className].join(' ')}>
            {children}
        </div>
    )
}

export default FieldsWrap;
