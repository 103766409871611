import React from 'react';
import Input from './fields/Input';
import Textarea from './fields/Textarea';
import Checkbox from './fields/Checkbox';

const FormikControl = (props) => {
    const { control, ...rest } = props;

    switch(control) {
        case 'input':
            return <Input {...rest} />;
        case 'textarea':
            return <Textarea {...rest} />;
        case 'checkbox':
            return <Checkbox {...rest} />;
        default:
            return null;
    }
}

export default FormikControl;
