import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field } from 'formik';

import Button         from '../../../atoms/button/button';
import FormikControl  from '../FormikControl';
import RequiredHint   from '../fields/RequiredHint';
import Modal          from '../../modal/Modal';
import Agb            from '../../modalContent/Agb';
import Privacy        from '../../modalContent/Privacy';

import styles         from './stepSend.module.css';


const StepSend = () => {
  const [modalAgb, setmodalAgb] = useState(false);
  const [modalPrivacy, setmodalPrivacy] = useState(false);

  const handleAgbClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setmodalAgb(true);
  }

  const handlePrivacyClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setmodalPrivacy(true);
  }

  return (
    <>
      <div role="group" aria-labelledby="checkbox-group">
        <FormikControl
          control="checkbox"
          name="agb"
          value="agb"
          label={<>Ich habe die Allgemeinen <Button className={styles.modalLink} tagName="button" htmlType="button" styleType="text" onClick={handleAgbClick}>Geschäftsbedingungen</Button> gelesen und akzeptiert! *</>}
        />
        <FormikControl
          control="checkbox"
          name="privacy"
          value="privacy"
          label={<>Ich habe die <Button className={styles.modalLink} tagName="button" htmlType="button" styleType="text" onClick={handlePrivacyClick}>Datenschutzerklärung</Button> gelesen und akzeptiert!  *</>}
        />
      </div>
      <FormikControl control="textarea" name="message" label="Möchten Sie uns noch etwas wissen lassen?" />
      <RequiredHint />
      <Field name="recaptcha">
        {field => (
          <>
            <ReCAPTCHA
              className={styles.recaptcha}
              sitekey="6LcEGdEZAAAAAO95-Fm_8RUbGktOegU-5eJoG4Qv"
              onChange={response => field.form.setFieldValue("recaptcha", response)}
            />
          </>
        )}
      </Field>

      <Modal open={modalAgb} handleClose={() => setmodalAgb(false)}><Agb /></Modal>
      <Modal open={modalPrivacy} handleClose={() => setmodalPrivacy(false)}><Privacy /></Modal>
    </>
  );
}

export default StepSend;
